
import { Component } from "vue-property-decorator";
import {
  Claim,
  ClaimCategory,
  ClaimReview,
  Factcheck,
  GoogleClaimReview,
  Partner,
  FactcheckType,
  ScrapedArticle,
} from "@/types";

import { ROOT_ACTIONS } from "@/store/actions";

import { TipTap } from "@/mixins/TipTap";
import { mixins } from "vue-class-component";
import { Fn, useIntervalFn } from "@vueuse/core";
import * as StorageServices from "@/services/StorageServices";
import * as FactCheckService from "@/services/FactCheckService";
import * as ScraperService from "@/services/ScraperService";
import * as ClaimCategoryService from '@/services/ClaimCategoryService';

@Component({
  components: {
    ScrapeResult: () => import("@/components/cards/ScrapeResult.vue"),
    ConfirmationDialog: () =>
      import("@/components/dialogs/ConfirmationDialog.vue"),
    NewFactCheckCategory: () =>
      import("@/components/dialogs/NewFactCheckCategory.vue"),
    ImportClaimReviewDialog: () =>
      import("@/components/dialogs/ImportClaimReview.vue"),
    ClaimReviewForm: () => import("@/components/forms/ClaimReviewForm.vue"),
    EditFactcheckSeoDialog: () => import("@/components/dialogs/EditFactcheckSeoDialog.vue"),
    ClaimForm: () => import("@/components/forms/ClaimForm.vue"),
    FactcheckForm: () => import("@/components/forms/FactcheckForm.vue"),
  },
})
export default class NewFactCheck extends mixins(TipTap) {
  step = 1;

  search = '';

  partners = [] as Partner[];

  prefilledFields: Array<{ from: string, field: string }> = [];

  today = new Date();
  date = `${this.today
    .toISOString()
    .slice(0, this.today.toISOString().indexOf("T"))}`;

  photoOverrrideWarning = false;
  imageError = false;

  imageUploadUrl = "";
  customImageSrc = "";

  imageSelectOptions = [
    { text: "Uploaden", value: "UPLOAD" },
    { text: "Afbeelding linken", value: "LINK" },
  ];

  imageSelect = "UPLOAD";

  factcheck: Partial<Factcheck> = {
    title: '',
    isPublished: false,
    description: '',
    type: FactcheckType.FACTCHECK,
    sortDate: new Date().toISOString().substring(0, 10),
    childFriendly: true,
  }

  claimReview: Partial<ClaimReview> = {
    languageCode: '',
    numericRating: 1,
    conclusion: '',
    textualRating: '',
    url: '',
    date: new Date().toISOString().substring(0, 10),
    title: '',
    PartnerId: '',
    hasVideo: false,
    isPriority: true,
    countryOfOrigin: 'BE',
  }

  claim: Partial<Claim> = {
    date: new Date().toISOString().substring(0, 10),
    claimant: '',
    source: '',
    text: '',
    associatedMedia: null,
    associatedMediaType: null,
    politicalParty: null,
    mediaformat: null,
    platform: null,
    createdAt: new Date(),
  }

  claimClaimCategories = [] as ClaimCategory[];


  dialogs = {
    datePicker: false,
    articleDatePicker: false,
    createArticle: false,
    newCategory: false,
    sync: false,
    editSeo: true,
  };

  loading = {
    createArticle: false,
  };

  usedScrapedArticle = {
    id: "",
  } as ScrapedArticle;


  get hasSavedConcept() {
    return localStorage.getItem('latest-concept') !== null
  }

  listClaimCategoryText() {
    const result = this.claimClaimCategories.map(e => {
      if (e.id) {
        return e.nl
      } else {
        return e
      }
    }).join(',')

    return result.length === 0 ? 'Geen categorie' : result
  }

  setClaimcategories(claimCategories: ClaimCategory[]) {
    this.claimClaimCategories = claimCategories;
  }


  pauseConceptSaving: Fn | null = null;

  deleteSavedConcept() {
    if (this.pauseConceptSaving) this.pauseConceptSaving()
    localStorage.removeItem('latest-concept')
    window.location.reload()
  }

  async mounted() {
    this.partners = await FactCheckService.GetPartners();

    // When used scraped article
    if (this.$route.query.article) {
      await this.setInputsFromScrapedArticle();
    } else if (this.$route.query.from === "google") {
      this.setInputsFromGoogleClaim();
    }

    const latestConcept = localStorage.getItem('latest-concept')

    if (latestConcept) {
      const concept = JSON.parse(latestConcept)
      this.claim = concept.claim
      this.claimReview = concept.claimReview
      this.factcheck = concept.factcheck
    }

    const inter = useIntervalFn(() => {
      localStorage.setItem('latest-concept', JSON.stringify({
        claim: this.claim,
        claimReview: this.claimReview,
        factcheck: this.factcheck,
      }))
    }, 5000)
    this.pauseConceptSaving = inter.pause;
  }

  async fetchClaimCategories(): Promise<ClaimCategory[]> {
    try {
      const result = await ClaimCategoryService.GetClaimCategories();
      return result;

    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
      return [];
    }
  }

  createdId = null as null | string;

  async createArticle() {
    let articleCreated = false;

    if (this.claimClaimCategories.length === 0) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Je moet een categorie selecteren'
      })
      return;
    }
    try {
      this.loading.createArticle = true;
      let imageUrl = '';

      let htmlVideoInputElement = document.getElementById("video") as HTMLInputElement;
      if (htmlVideoInputElement) {
        if (htmlVideoInputElement.files) {
          const video = htmlVideoInputElement.files[0];
          if (video) this.claimReview.hasVideo = true;
        }
      }

      // Handle photo
      switch (this.imageSelect) {
        case "UPLOAD": {
          const tempImageId = (Math.random() + 1).toString(36).substring(7);

          let htmlElement = document.getElementById("photo") as HTMLInputElement;

          if (!htmlElement) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
              color: 'error',
              text: 'error',
            })
            return;
          }

          if (!htmlElement.files) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
              color: 'error',
              text: 'error',
            })
            return;
          }

          const file = htmlElement.files[0];
          await StorageServices.UploadPhoto(`images/${tempImageId}`, file);
          imageUrl = await StorageServices.GetDownloadUrl(`images/${tempImageId}`);

          break;
        }

        case "SCRAPED": {
          imageUrl = await StorageServices.GetDownloadUrl(
            `images/${this.usedScrapedArticle.id}`
          );

          break;
        }

        case "LINK": {
          imageUrl = this.customImageSrc;
          break;
        }
      }

      // Check if new Claim Category should be created
      for (let i = 0; i < this.claimClaimCategories.length; i++) {
        const cc = this.claimClaimCategories[i];
        if (typeof cc === 'string') {
          const category = await ClaimCategoryService.CreateClaimCategory(cc);
          this.claimClaimCategories[this.claimClaimCategories.indexOf(cc)] = category;
        }
      }

      const claim = await FactCheckService.CreateClaim(this.claim);
      await FactCheckService.CreateClaimReview(claim.id, {
        ...this.claimReview,
        fullText: this.$route.query.article ? this.usedScrapedArticle.fullArticle : '',
      }, this.usedScrapedArticle.id.length > 0 ? this.usedScrapedArticle.id : null, imageUrl)

      const factcheck = await FactCheckService.CreateFactcheck({
        ...this.factcheck,
        ClaimId: claim.id,
        sortDate: new Date(this.claimReview.date as string),
      });

      await FactCheckService.SetClaimCategories(claim.id, this.claimClaimCategories.map(e => e.id))

      this.createdId = factcheck.id as string;

      // Handle Video
      if (this.claimReview.hasVideo) {
        //@ts-ignore
        //Video is present - ignore
        await StorageServices.UploadPhoto(`/videos/${factcheck.id}`, htmlVideoInputElement.files[0])
      }

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Artikel aangemaakt",
      });


      articleCreated = true;

      if (this.pauseConceptSaving) this.pauseConceptSaving();
      const latestConcept = localStorage.getItem('latest-concept')

      if (latestConcept) {
        localStorage.removeItem('latest-concept')
      }
    } catch (error) {
      console.log(error);
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    } finally {
      this.loading.createArticle = false;
      this.dialogs.createArticle = false;
      if (articleCreated) {
        this.dialogs.sync = true;
      }

      if (this.$route.query.article) {
        await ScraperService.UpdateScrapedArticle(
          this.usedScrapedArticle.id,
          { used: true }
        );
      }
    }
  }

  conceptId: null | string = null;

  showImagePreview() {
    this.customImageSrc = URL.createObjectURL(
      //@ts-ignore
      document.getElementById("photo").files[0]
    );
    this.$forceUpdate();
  }

  async setInputsFromScrapedArticle() {
    this.imageSelectOptions.unshift({
      text: "Gescrapte afbeelding",
      value: "SCRAPED",
    });
    this.imageSelect = "SCRAPED";

    this.usedScrapedArticle = await ScraperService.GetScrapedArticle(this.$route.query.article as string);

    if (['DPA', 'NIEUWSCHECKERS_NL'].includes(this.usedScrapedArticle.PartnerId)) {
      this.claimReview.countryOfOrigin = 'NL'
    } else {
      this.claimReview.countryOfOrigin = 'BE'
    }

    this.usedScrapedArticle.id = this.$route.query.article as string;
    this.factcheck.title = this.usedScrapedArticle.title;
    this.factcheck.description = this.usedScrapedArticle.description;

    this.claimReview.PartnerId = this.usedScrapedArticle.PartnerId;

    this.claimReview.title = this.usedScrapedArticle.title;
    this.claimReview.url = this.usedScrapedArticle.url;
    this.claimReview.date = this.usedScrapedArticle.date ? new Date(this.usedScrapedArticle.date).toISOString().substring(0, 10) : new Date(this.usedScrapedArticle.createdAt).toISOString().substring(0, 10)

    this.claimReview.publisher = this.usedScrapedArticle.factCheckAuthor;
    this.claimReview.url = this.usedScrapedArticle.url;

    if (this.usedScrapedArticle.claimReviewData) {
      const cr = this.usedScrapedArticle.claimReviewData
      if (cr.claimReviewed) {
        this.claim.text = cr.claimReviewed
        this.prefilledFields.push({ from: 'claimReview', field: 'text' })
      }

      if (cr.itemReviewed.author) {
        this.claim.claimant = cr.itemReviewed.author.name
        this.prefilledFields.push({ from: 'claimReview', field: 'claimant' })
      }

      if (cr.itemReviewed.appearance[0]) {
        this.claim.source = cr.itemReviewed.appearance[0].url || ''
        this.prefilledFields.push({ from: 'claimReview', field: 'source' })
      }

      if (cr.reviewRating.alternateName) {
        if (cr.author.name === 'dpa') {
          this.claimReview.conclusion = cr.reviewRating.alternateName
        }
        this.claimReview.textualRating = cr.reviewRating.alternateName
        this.prefilledFields.push({ from: 'claimReview', field: 'textualRating' })
      }

      if (cr.reviewRating.ratingValue) {
        this.claimReview.numericRating = parseInt(cr.reviewRating.ratingValue, 10);
        this.prefilledFields.push({ from: 'claimReview', field: 'numericRating' })
      }

      if (cr.datePublished) {
        this.claimReview.date = new Date(cr.datePublished).toISOString().substring(0, 10)
        this.prefilledFields.push({ from: 'claimReview', field: 'claimreview-date' })
      }

      if (cr.itemReviewed.datePublished) {
        this.claim.date = new Date(cr.itemReviewed.datePublished).toISOString().substring(0, 10)
        this.prefilledFields.push({ from: 'claimReview', field: 'claim-date' })
      }
    }
  }

  async setInputsFromConcept() {
    this.conceptId = this.$route.query.concept as string;
    const concept = await FactCheckService.GetConcept(this.conceptId);
    this.factcheck = concept.factcheck;
    this.claim = concept.claim;
    this.claimReview = concept.claimReview;
  }

  setInputsFromGoogleClaim() {
    const claim = JSON.parse(
      this.$route.query.claim as string
    ) as GoogleClaimReview;
    this.claim.text = claim.text;

    this.claimReview.PartnerId = 'UNKNOWN';
    this.claimReview.textualRating = claim.claimReview[0].textualRating;
    this.claimReview.url = claim.claimReview[0].url;
    this.factcheck.title = claim.claimReview[0].title;
  }
}
